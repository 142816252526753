<template>
  <div class="list-info">
    <top-bar :title="'巡查'" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入巡查人姓名" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="8">
          <p @click="gridShow=!gridShow" :style="{color:gridShow?'#387FF5':'#666666'}">
            {{gridName}}
            <img :src="require(`@/assets/img/${gridShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="dateBarShow=!dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666' }">巡查时间</p>
        </van-col>
        <van-col span="8">
          <p @click="userShow=!userShow" :style="{color:userShow?'#387FF5':'#666666'}">
            {{userName}}
            <img :src="require(`@/assets/img/${userShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="gridShow" position="bottom">
      <van-picker title="请选择" show-toolbar :columns="gridList" value-key="label" @confirm="gridConfirm"
                  cancel-button-text="重置"
                  @cancel="gridCancel"  />
    </van-popup>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup v-model="beginDateShow" position="bottom">
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false"  type="date"
                           :formatter="formatDate" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom">
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="date" :formatter="formatDate"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date(new Date().getFullYear()+5, 10, 1)"/>
    </van-popup>
    <van-popup v-model="userShow" position="bottom">
      <van-picker title="请选择" show-toolbar :columns="userList" value-key="label" @confirm="userConfirm"
                  cancel-button-text="重置"
                  @cancel="userCancel"
      />
    </van-popup>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
      <p>新增、添加</p>
    </div>

    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
            <div class="title">
              <div class="title-left">
                {{item.createUserName + ' | ' + item.inspectionTime}}
              </div>
              <div class="title-right">
                {{item.inspectionTypeStr}}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.filesPath.length!==0?item.filesPath[0].url:require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{item.address}}</div>
                <div class="bottom-text">{{item.inspectionDes}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {newDate} from '@/utils/utils'
import { mapMutations } from 'vuex'
import {getImageStream} from '@/utils/index'

export default {
  components: {
    topBar
  },
  data() {
    return {
      gridName: '所属网格',
      userName: '所属社工',
      loading: false,
      finished: false,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      gridShow: false,
      userShow: false,
      pullLoading:false,
      page: 0,
      limit: 10,
      searchValue: '',
      selectDate: {
        endDate: '',
        beginDate: ''
      },
      gridList: [],
      userList: [],
      totalCount: 0,
      dataList: []
    };
  },
  methods: {
    ...mapMutations,
    getInfo (id) {
      this.$router.push({path: '/patrol-add', query: {id: id}})
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/inspection/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          name: this.searchValue,
          startDates: this.selectDate.beginDate,
          endDates: this.selectDate.endDate,
          gridId: this.gridId,
          workerType: this.userId
        })
      }).then(({data})=> {
        this.pullLoading =false
        if (data.code == 0) {
          this.totalCount = data.page.totalCount
          let dataList = data.page.list
          dataList.map(item => {
            if (item.inspectionTime) {
              item.inspectionTime = item.inspectionTime.split(' ')[0]
            }
          })
          this.dataList = this.dataList.concat(data.page.list)
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    getGridList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/grid/info/listByUser'),
        method: 'post',
        params: this.$http.adornParams({
          community: this.$globalData.userInfo.orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.gridList = data.grids
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    getUserList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/orgUserManagerTree'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$globalData.userInfo.orgId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          data.userManagerTree.map(item => {
            if (item.label === '社工') {
              this.userList = item.children
            }
          })
          // 默认显示当前社工名字
          let that = this
          this.userList.forEach(function(item) {
            if (item.value == that.$globalData.userInfo.userId) {
              that.userName = item.label
              return
            }
          });
          this.userList.unshift({
            label: '所有',
            value: 0
          })
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    onSearch () {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel () {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    beginDateConfim (value) {
      let d = newDate(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = newDate(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
    changeDate () {
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    dateClose () {
      this.selectDate = {
        endDate: '',
        beginDate: ''
      }
    },
    /**
     * 页面相关事件处理函数--用户下拉动作
     */
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    gridConfirm (value,index) {
      this.page = 0
      this.dataList = []
      this.gridName = value.label
      this.gridId = value.value
      this.getDataList()
      this.gridShow = false
    },
    gridCancel (value) {
      this.page = 0
      this.dataList = []
      this.gridName = '所属网格'
      this.gridId = ''
      this.getDataList()
      this.gridShow = false
    },
    userConfirm(value, index) {
      this.page = 0
      this.dataList = []
      this.userName = value.label
      this.userId = value.value
      this.getDataList()
      this.userShow = false
    },
    userCancel (value) {
      this.page = 0
      this.dataList = []
      this.userName = '所属社工'
      this.userId = ''
      this.getDataList()
      this.userShow = false
    },
    goAdd () {
      this.$router.push('/patrol-add')
    }
  },
  created () {
    this.userId = this.$globalData.userInfo.userId
    this.getDataList()
    this.getGridList()
    this.getUserList()
  }
}
</script>
